import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { LG } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import WelcomeSectionText from '../welcome-section-text';

const Ul = styled.ul`
  padding: 0;
  margin: ${theme.spacing(6)} 0;
  list-style: none;

  @media ${LG} {
    margin: ${theme.spacing(12)} 0;
  }
`;

const Li = styled.li`
  display: flex;
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};
  align-items: center;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  @media ${LG} {
    margin-top: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(8)};
  }
`;

const Checkmark = styled(CheckIcon)`
  margin-right: ${theme.spacing(4)};
  color: ${theme.palette.success.main};
  font-size: 2.2rem;
`;

const PointsList: React.FC<{ points: string[] }> = ({ points }) => (
  <Ul>
    {points.map(point => (
      <Li key={point}>
        <Checkmark />
        <WelcomeSectionText variant="h6">{point}</WelcomeSectionText>
      </Li>
    ))}
  </Ul>
);

export default PointsList;
