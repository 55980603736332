import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Img from 'components/shared/img';
import theme from 'src/shared/theme';
import { XS } from 'src/shared/media-query';
import Text from 'components/shared/text';
import { FixedObject } from 'gatsby-image';

interface Props {
  className?: string;
  photoFixed: FixedObject | string;
  name: string;
  specialization?: string;
  about?: string;
  longestTeamMemberNameLength: number;
  longestTeamMemberSpecializationLength: number;
}

const IMG_SIZE = 200;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
`;

const RoundedImage = styled.div`
  border-radius: 1000px;
  border-width: ${theme.spacing(2)};
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${theme.spacing(5)};
  height: ${IMG_SIZE}px;
  width: ${IMG_SIZE}px;
`;

const About = styled(Text)`
  margin-top: ${theme.spacing(4)};
`;

const Name = styled(Text)`
  display: inline-flex;
  align-items: center;
`;

function getHeight(length = 1, lineLength = 15) {
  const lineHeight = 1.2;

  const lineCount = Math.ceil(length / lineLength);

  return `${lineCount * lineHeight}em`;
}

const Member: FC<Props> = (props) => (
  <Container className={props.className}>
    <RoundedImage>
      <Img src={props.photoFixed} alt={props.name} css={css`height: 200px; width: 200px;`} />
    </RoundedImage>
    <Name
      variant="h6"
      style={{ height: getHeight(props.longestTeamMemberNameLength) }}
      gutterBottom
      css={css`
        font-size: 1.3rem;
        @media ${XS}{
          font-size: 1.375rem;
        }
      `}
    >
      {props.name}
    </Name>
    <Text variant="body2" color="primary">
      {props.specialization}
    </Text>
    <About variant="body2">{props.about}</About>
  </Container>
);

export default Member;
