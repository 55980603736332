import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { SM } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShowUpMotion from 'components/shared/show-up-motion';
import SectionContent from 'components/shared/section-content';
import SectionTitle from 'components/shared/section-title';
import SectionSubtitle from 'components/shared/section-subtitle';
import SectionContainer from 'components/shared/section-container';
import Member from './member';

interface Props {
  className?: string;
}
const MEMBER_SIDE_PADDING = 8;
export const XL_MEMBER_SIDE_PADDING = 44;
const MEMBER_WIDTH = 216 + 2 * MEMBER_SIDE_PADDING;
export const XL_MEMBER_WIDTH = 216 + 2 * XL_MEMBER_SIDE_PADDING;
const MOBILE_BUTTON_WIDTH = 44; // 2.75rem
const BUTTON_WIDTH = 64; // 4rem

const Members = styled.div`
  padding-top: ${theme.spacing(4)};
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;

  width: ${MEMBER_WIDTH}px;
  @media (min-width: ${2 * MEMBER_WIDTH + MOBILE_BUTTON_WIDTH * 2}px) {
    width: ${2 * MEMBER_WIDTH}px;
  }
  @media (min-width: ${3 * MEMBER_WIDTH + BUTTON_WIDTH * 2}px) {
    width: ${3 * MEMBER_WIDTH}px;
  }
  @media (min-width: ${4 * MEMBER_WIDTH + BUTTON_WIDTH * 2}px) {
    width: ${4 * MEMBER_WIDTH}px;
  }
  @media (min-width: ${5 * MEMBER_WIDTH + BUTTON_WIDTH * 2}px) {
    width: ${5 * MEMBER_WIDTH}px;
  }
  @media (min-width: ${5 * XL_MEMBER_WIDTH + BUTTON_WIDTH * 2}px) {
    width: ${5 * XL_MEMBER_WIDTH}px;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  scroll-behavior: smooth;
`;

const MemberItem = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 ${MEMBER_SIDE_PADDING}px;
  min-width: ${MEMBER_WIDTH}px;

  @media (min-width: ${5 * XL_MEMBER_WIDTH + BUTTON_WIDTH * 2}px) {
    padding: 0 ${XL_MEMBER_SIDE_PADDING}px;
    min-width: ${XL_MEMBER_WIDTH}px;
  }
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ScrollButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 5rem;

  @media ${SM} {
    height: 4rem;
    width: 4rem;
    border-radius: 1000px;
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.palette.shadow};
  }
`;

const OurTeamSection: FC<Props> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "our-team-section" } }) {
          frontmatter {
            title
            subtitle
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "team-member" } } }
          sort: { fields: frontmatter___order }
        ) {
          nodes {
            frontmatter {
              name
              specialization
              photo {
                childImageSharp {
                  fixed(width: 200, height: 200, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
                publicURL
              }
              about
            }
          }
        }
      }
    `
  );
  const membersContainer = useRef<any>();
  const longestTeamMemberNameLength = Math.max(
    ...data.allMarkdownRemark.nodes.map(node => node.frontmatter.name.length)
  );
  const longestTeamMemberSpecializationLength = Math.max(
    ...data.allMarkdownRemark.nodes.map(node => node.frontmatter.specialization.length)
  );

  const isXl = useMediaQuery(theme.breakpoints.up(`xl`));

  function scroll(direction: `left` | `right`) {
    const currentPosition = membersContainer.current.scrollLeft;
    const stepSize = isXl ? XL_MEMBER_WIDTH : MEMBER_WIDTH;
    const step = direction === `right` ? stepSize : -stepSize;

    function getNextPosition() {
      const currentStepLength = Math.floor(currentPosition / stepSize);
      const currentStep = stepSize * currentStepLength;

      return currentStep + step;
    }

    membersContainer.current.scrollTo(getNextPosition(), 0);
  }

  return (
    <SectionContainer>
      <SectionContent>
        <ShowUpMotion
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {triggerElement => (
            <>
              <SectionTitle>
                {data.markdownRemark.frontmatter.title}
              </SectionTitle>
              <SectionSubtitle>
                {data.markdownRemark.frontmatter.subtitle}
              </SectionSubtitle>
              <MembersContainer ref={triggerElement}>
                <ScrollButton
                  aria-label="Předchozí"
                  color="primary"
                  onClick={() => scroll(`left`)}
                >
                  <ChevronLeft fontSize="large" />
                </ScrollButton>
                <Members ref={membersContainer}>
                  {data.allMarkdownRemark.nodes.map(
                    ({
                      frontmatter: { name, specialization, photo, about },
                    }) => (
                      <MemberItem key={`${name}`}>
                        <Member
                          photoFixed={photo.childImageSharp?.fixed || photo.publicURL}
                          name={name}
                          specialization={specialization}
                          about={about}
                          longestTeamMemberNameLength={
                            longestTeamMemberNameLength
                          }
                          longestTeamMemberSpecializationLength={
                            longestTeamMemberSpecializationLength
                          }
                        />
                      </MemberItem>
                    )
                  )}
                </Members>
                <ScrollButton
                  aria-label="Předchozí"
                  color="primary"
                  onClick={() => scroll(`right`)}
                >
                  <ChevronRight fontSize="large" />
                </ScrollButton>
              </MembersContainer>
            </>
          )}
        </ShowUpMotion>
      </SectionContent>
    </SectionContainer>
  );
};

export default OurTeamSection;
