import React from 'react';
import styled from 'styled-components';
import { Typography, useMediaQuery } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

import { LG, MD, SM } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import Button from 'src/components/shared/button';
import { showUp } from 'src/shared/animations';

import { DEFAULT_SPACING } from 'src/shared/styles/page-margin';
import SectionContainer from '../../shared/section-container';
import SectionContent from '../../shared/section-content';
import MailLink from '../../shared/mail-link';
import PointsList from './points-list';
import ItemWithIcon from './item-with-icon';
import MappedPhones from '../../shared/mapped-phones';
import SlideShow from './slide-show';

const Content = styled(SectionContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${theme.spacing(6)};

  @media ${SM} {
    flex-direction: row;
    margin-top: ${theme.spacing(12)};
  }
`;

const ReserveButton = styled(Button)`
  margin-bottom: ${theme.spacing(6)};

  @media ${LG} {
    margin-bottom: ${theme.spacing(12)};
  }
`;

const TextContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 2 2 0;

  @media ${MD} {
    margin-top: ${theme.spacing(10)};
  }
`;

const Contact = styled(ItemWithIcon)`
  margin-bottom: ${theme.spacing(4)};
  color: ${theme.palette.text.primary};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Carousel = styled(SlideShow)`
  width: 100%;
  height: calc((100vw - 2 * ${DEFAULT_SPACING}) / (4 / 3));
  margin: 0 auto;
  margin-top: ${theme.spacing(8)};

  @media ${SM} {
    width: 540px;
    height: 405px;
    margin-top: ${theme.spacing(12)};
    margin-left: ${theme.spacing(10)};
  }

  @media ${LG} {
    width: 840px;
    height: 630px;
    margin-top: 0;
  }
`;

const animations = {
  textContainer: {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.25,
      },
    },
  },
  textItem: showUp,
  button: {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: { type: `spring`, bounce: 0.3 },
    },
  },
};

export const WelcomeSectionTemplate = ({
  heading,
  points,
  phones,
  email,
  slideshowPhotos,
}) => {
  const isLarge = useMediaQuery(theme.breakpoints.up(`xs`));

  return (
    <SectionContainer>
      <Content>
        <TextContent
          variants={animations.textContainer}
          initial="hidden"
          animate="visible"
        >
          <motion.div variants={animations.textItem}>
            <Typography variant="h1">{heading}</Typography>
          </motion.div>
          <motion.div variants={animations.textItem}>
            <PointsList points={points} />
          </motion.div>
          <motion.div variants={animations.button}>
            <ReserveButton
              to="/rezervace"
              variant="contained"
              color="primary"
              size={isLarge ? `large` : `medium`}
            >
              Rezervovat termín návštěvy
            </ReserveButton>
          </motion.div>
          <motion.div variants={animations.textItem}>
            <Contact Icon={PhoneIcon}>
              <MappedPhones phones={phones} />
            </Contact>
            <Contact Icon={MailIcon}>
              <MailLink mail={email} />
            </Contact>
          </motion.div>
        </TextContent>
        {!!slideshowPhotos && <Carousel images={slideshowPhotos} />}
      </Content>
    </SectionContainer>
  );
};

const WelcomeSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        welcomeSection: markdownRemark(frontmatter: { id: { eq: "welcome-section" } }) {
          frontmatter {
            heading
            points
            main_photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            secondary_photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            slideshowPhotos {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        contacts: markdownRemark(frontmatter: { id: { eq: "contacts" } }) {
          frontmatter {
            email
            phones
          }
        }
      }
    `
  );

  const {
    heading,
    points,
    slideshowPhotos: unmappedImages,
  } = data.welcomeSection.frontmatter;

  const {
    phones,
    email,
  } = data.contacts.frontmatter;

  const slideshowPhotos = unmappedImages.map(
    obj => obj.src.childImageSharp.fluid
  );

  return WelcomeSectionTemplate({
    heading,
    points,
    phones,
    email,
    slideshowPhotos,
  });
};

export default WelcomeSection;
