import React, { FC } from 'react';
import styled from 'styled-components';
import IconWithBackground from 'src/components/shared/icon-with-background';
import theme from 'src/shared/theme';
import WelcomeSectionText from '../welcome-section-text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(WelcomeSectionText)`
  margin-left: ${theme.spacing(2)};
  font-weight: ${theme.typography.fontWeightMedium};
`;

const IconCircle = styled(IconWithBackground)`
  border-radius: 100px;
`;

const ItemWithIcon: FC<{
  Icon;
  iconBackgroundColor?;
  children?;
  showShadow?: boolean;
  className?: string;
}> = ({
  Icon,
  iconBackgroundColor,
  children,
  showShadow = false,
  className,
}) => (
  <Wrapper className={className}>
    <IconCircle
      Icon={Icon}
      color={iconBackgroundColor}
      showShadow={showShadow}
      sizes={{ base: 3.625 }}
    />
    <Text variant="body1" color="inherit">
      {children}
    </Text>
  </Wrapper>
);

export default ItemWithIcon;
