import styled from 'styled-components';
import theme from 'src/shared/theme';
import React, { FC } from 'react';
import { pageSectionMargin } from 'src/shared/styles/page-margin';

interface Props {
  className?: string;
}

const Container = styled.div`
  ${pageSectionMargin}
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${theme.breakpoints.values.lg}px;
`;

const SectionContent: FC<Props> = props => (
  <Container className={props.className}>{props.children}</Container>
);

export default SectionContent;
