import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { LG, MD, SM, XS } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import ShowUpMotion from 'components/shared/show-up-motion';
import SectionContent from 'components/shared/section-content';
import SectionContainer from 'components/shared/section-container';
import WorkingHours from './working-hours';
import Webcam from './webcam';
import GoogleMap from './google-map';
import Parking from './parking';
import Payments from './payments';

const Container = styled(SectionContainer)`
  position: relative;
  background-color: #daf3ff;

  @media ${XS} {
    background-color: transparent;
    background-image: url('/assets/homepage-solid-background.svg');
    background-size: 100% 100%;
  }
`;

const halfOfWebcam = `calc((650px - ${theme.spacing(7)}) / 2)`;

const Cards = styled.section`
  max-width: ${theme.breakpoints.values.lg}px;
  display: grid;
  grid-gap: ${theme.spacing(7)};
  align-items: stretch;
  margin: 0 auto;

  grid-template:
    'working-hours'
    'webcam'
    'map'
    'parking'
    'credit-card-support';

  @media ${XS} {
    grid-template:
      'working-hours      working-hours ' auto
      'webcam             parking ' auto
      'webcam             credit-card-support ' auto
      'map                map' auto
      / minmax(330px, 650px) minmax(210px, auto);
  }

  @media (min-width: 800px) {
    grid-template:
      'working-hours       map ' auto
      'webcam              map' auto
      'webcam              parking ' auto
      'webcam              credit-card-support ' auto
      / minmax(250px, auto) minmax(210px, auto);
  }

  @media ${SM} {
    grid-template:
      'working-hours        webcam ' auto
      'working-hours        map    ' auto
      'credit-card-support  parking' auto
      / auto auto;
  }

  @media (min-width: 1200px) {
    grid-template:
      'working-hours        working-hours             webcam' auto
      'working-hours        working-hours             map' minmax(0, auto)
      'credit-card-support  parking                   map' auto
      / auto auto minmax(590px, auto);
  }

  @media ${MD} {
    grid-template:
      'working-hours          webcam       webcam               map' auto
      'working-hours          parking      credit-card-support  map' auto
      / 6fr ${halfOfWebcam} ${halfOfWebcam} 3fr;
  }

  @media ${LG} {
    grid-template:
      'working-hours          webcam                           map' auto
      'working-hours          webcam                       parking' 127px
      'working-hours          webcam           credit-card-support' 127px
      /4fr 650px auto;
  }
`;

const WorkingHoursCard = styled(WorkingHours)`
  grid-area: working-hours;
`;

const WebcamCard = styled(Webcam)`
  grid-area: webcam;
`;

const MapCard = styled(GoogleMap)`
  grid-area: map;
`;

const ParkingCard = styled(Parking)`
  grid-area: parking;
`;

const CreditCardSupportCard = styled(Payments)`
  grid-area: credit-card-support;
`;

const MainInfoSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "contacts" } }) {
          frontmatter {
            workingHoursCaption
            workingHoursValues {
              days
              time
            }
            emergencyHoursCaption
            emergencyDescription
            emergencyPhoneCaption
            emergencyPhoneNumber
            webcamTitle
            webcamNote
            parkingTitle
            parkingNote
            paymentsTitle
          }
        }
      }
    `
  );

  const {
    emergencyHoursCaption,
    emergencyDescription,
    emergencyPhoneCaption,
    emergencyPhoneNumber,
    webcamTitle,
    webcamNote,
    parkingTitle,
    parkingNote,
    paymentsTitle,
  } = data.markdownRemark.frontmatter;

  return (
    <Container id="kontakt">
      <SectionContent>
        <ShowUpMotion>
          {triggerElement => (
            <Cards>
              <WorkingHoursCard
                emergencyDescription={emergencyDescription}
                emergencyHoursCaption={emergencyHoursCaption}
                emergencyPhoneCaption={emergencyPhoneCaption}
                emergencyPhoneNumber={emergencyPhoneNumber}
                ref={triggerElement}
              />
              <WebcamCard webcamNote={webcamNote} webcamTitle={webcamTitle} />
              <MapCard />
              <ParkingCard parkingNote={parkingNote} parkingTitle={parkingTitle} />
              <CreditCardSupportCard paymentsTitle={paymentsTitle} />
            </Cards>
          )}
        </ShowUpMotion>
      </SectionContent>
    </Container>
  );
};

export default MainInfoSection;
