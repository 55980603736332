import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'src/components/shared/button';
import { Icon as MaterialIcon } from '@material-ui/core';
import { Link } from 'gatsby-theme-material-ui';
import theme from 'src/shared/theme';
import { SM, XS } from 'src/shared/media-query';
import ShowUpMotion from 'components/shared/show-up-motion';
import TileButton from '../../shared/tile-button';
import SectionContent from '../../shared/section-content';
import SectionTitle from '../../shared/section-title';
import SectionSubtitle from '../../shared/section-subtitle';
import SectionContainer from '../../shared/section-container';

interface Props {
  className?: string;
}

const Container = styled(SectionContainer)`
  background-repeat: no-repeat;
  background-color: #e9f7ff;

  @media ${XS} {
    background-color: transparent;
    background-image: url('/assets/services-background.svg');

    background-size: 800% 109%;
    background-position: -1000px 0;
  }

  @media ${SM} {
    background-size: 100% 107%;
    background-position: 0 -3%;
  }
`;

const MoreAboutServices = styled(Button)`
  align-self: center;
  margin-top: ${theme.spacing(17)};
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  vertical-align: top;
`;

const Icon = styled(MaterialIcon)`
  display: inline-block;
`;

const LinkWrapper = styled(Link)`
  margin: ${theme.spacing(1)};
  width: 42vw;
  height: 42vw;

  @media ${XS} {
    margin: ${theme.spacing(4)};
    width: 14rem;
    height: 14rem;
  }

  @media ${SM} {
    margin: ${theme.spacing(4)};
    width: 21.25rem;
    height: 21.25rem;
  }
`;

const TileButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -${theme.spacing(4)};
`;

const TileButtonIcon = function getTileButtonIcon(iconUrl) {
  return function tileButtonIcon(props) {
    return (
      <Icon {...props}>
        <Img src={iconUrl} />
      </Icon>
    );
  };
};

const ServicesSection: FC<Props> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "services-section" } }) {
          frontmatter {
            title
            subtitle
            moreAboutServicesButtonText
          }
        }
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(/sluzby/)/" } }
          sort: { fields: frontmatter___order }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              icon {
                publicURL
              }
              description
            }
          }
        }
      }
    `,
  );

  return (
    <Container id="sluzby">
      <SectionContent>
        <ShowUpMotion
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {(triggerElement) => (
            <>
              <SectionTitle>
                {data.markdownRemark.frontmatter.title}
              </SectionTitle>
              <SectionSubtitle>
                {data.markdownRemark.frontmatter.subtitle}
              </SectionSubtitle>
              <TileButtons ref={triggerElement}>
                {data.allMarkdownRemark.nodes.map(
                  ({ frontmatter, fields: { slug } }) => (
                    <LinkWrapper to={slug} key={slug} underline="none">
                      <TileButton
                        Icon={TileButtonIcon(frontmatter.icon.publicURL)}
                        caption={frontmatter.title}
                        description={frontmatter.description}
                      />
                    </LinkWrapper>
                  ),
                )}
              </TileButtons>
            </>
          )}
        </ShowUpMotion>
        <MoreAboutServices
          to="/sluzby"
          variant="contained"
          color="secondary"
          size="large"
        >
          {data.markdownRemark.frontmatter.moreAboutServicesButtonText}
        </MoreAboutServices>
      </SectionContent>
    </Container>
  );
};

export default ServicesSection;
