import { motion } from 'framer-motion';
import React, { FC } from 'react';

import { showUp } from 'src/shared/animations';
import useAnimationOnInView from 'src/shared/hooks/useAnimationOnInView';

interface Props {
  className?: string;
}

const ShowUpMotion: FC<Props> = ({ children, ...rest }) => {
  const { control, triggerElement } = useAnimationOnInView({
    startVariant: `visible`,
  });

  return (
    <>
      <motion.div
        ref={triggerElement}
        animate={control}
        initial="hidden"
        variants={showUp}
        {...rest}
      >
        {typeof children === `function` ? children(triggerElement) : children}
      </motion.div>
    </>
  );
};

export default ShowUpMotion;
