import React, { FC } from 'react';
import { SM, XS } from 'src/shared/media-query';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import theme from 'src/shared/theme';
import styled from 'styled-components';
import NewsCard from './card';

interface Props {
  className?: string;
  newestPosts: {
    date: Date;
    title: string;
    slug: string;
    description: string;
  }[];
  ref?: React.Ref<HTMLDivElement>;
}

const Cards = styled.div`
  display: flex;

  ${getFlexGap(2)}

  @media ${XS} {
    ${getFlexGap(7)}
  }
`;

const Card = styled(NewsCard)`
  @media ${XS} {
    width: calc(100% / 2 - ${theme.spacing(7)});
  }

  @media ${SM} {
    width: calc(100% / 3 - ${theme.spacing(7)});
  }
`;

const NewsCards: FC<Props> = React.forwardRef(
  (props, ref: React.Ref<HTMLDivElement>) => (
    <Cards className={props.className} ref={ref}>
      {props.newestPosts.map(post => (
        <Card
          key={post.slug}
          date={post.date}
          title={post.title}
          slug={post.slug}
          description={post.description}
        />
      ))}
    </Cards>
  )
);

export default NewsCards;
