import React, { FC } from 'react';
import styled from 'styled-components';
import { CardContent } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Card from 'components/shared/card';
import { SM, XS } from 'src/shared/media-query';
import EllipsisText from 'components/shared/ellipsis-text';
import { Link } from 'gatsby-theme-material-ui';
import theme from 'src/shared/theme';
import useHover from 'src/shared/hooks/useHover';
import Text from 'components/shared/text';

interface Props {
  className?: string;
  date: Date;
  title: string;
  description: string;
  slug: string;
}

const Container = styled(Card)`
  border-radius: 0.4rem;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: visible;
  background-color: transparent;
`;

const MovingContainerPart = styled.div<{ $isHovered }>`
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  border-radius: 0.4rem;
  background-color: ${theme.palette.primary.light};
  box-shadow: none;
  transition: all 300ms ease;
  ${({ $isHovered }) =>
    $isHovered
      ? `
      background-color: ${theme.palette.primary.dark};
      transform: translateY(-${theme.spacing(2)});
      box-shadow: 0px 10px 19px -8px rgba(0,0,0,0.29);
    `
      : null}
`;

const ContainerContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${theme.spacing(6)};

  &:last-child {
    padding-bottom: ${theme.spacing(6)};
  }

  @media ${SM} {
    padding: ${theme.spacing(15)};
    padding-top: ${theme.spacing(12)};

    &:last-child {
      padding-bottom: ${theme.spacing(12)};
    }
  }
`;

const DividerRect = styled.div`
  border-radius: 5px;
  background-color: ${theme.palette.primary.main};
  height: 5px;
  width: 72px;
  margin: 0;
  margin-bottom: ${theme.spacing(5)};
  display: none;

  @media ${XS} {
    display: block;
  }
`;

const DateCreated = styled(Text)<{ $isHovered }>`
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};
  color: ${({ $isHovered }) =>
    $isHovered
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary};
  transition: all 300ms ease;
  font-size: 1.1rem;
`;

const Calendar = styled(CalendarIcon)`
  margin-right: ${theme.spacing(1)};
  font-size: 1rem;
`;

const Title = styled(Text)<{ $isHovered }>`
  margin-bottom: ${theme.spacing(1)};
  color: ${({ $isHovered }) =>
    $isHovered
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary};
  transition: all 300ms ease;
  font-size: 1.3rem;

  @media ${XS} {
    margin-bottom: ${theme.spacing(4)};
    font-size: 1.375rem;
  }
`;

const Description = styled(Text)<{ $isHovered }>`
  color: ${({ $isHovered }) =>
    $isHovered
      ? theme.palette.text.darkContrast
      : theme.palette.text.lightContrast};
  transition: all 300ms ease;

  font-size: 1.1rem;
`;

const NewsCard: FC<Props> = props => {
  const [hoverRef, isHovered] = useHover();

  return (
    <Container ref={hoverRef} className={props.className}>
      <Link to={props.slug} underline="none" color="inherit">
        <MovingContainerPart $isHovered={isHovered}>
          <ContainerContent>
            <DividerRect />
            <DateCreated variant="body2" $isHovered={isHovered}>
              <Calendar />
              {props.date.toLocaleDateString()}
            </DateCreated>
            <Title variant="h6" $isHovered={isHovered}>
              {props.title}
            </Title>
            <Description variant="body2" $isHovered={isHovered}>
              <EllipsisText maxLength={135}>{props.description}</EllipsisText>
            </Description>
          </ContainerContent>
        </MovingContainerPart>
      </Link>
    </Container>
  );
};

export default NewsCard;
