import { RefObject, useEffect, useRef, useState } from "react";

function useHover<T extends HTMLElement>() {
  const [value, setValue] = useState(false);

  const ref = useRef() as RefObject<T>;

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;

      if (node) {
        node.addEventListener(`mouseover`, handleMouseOver);
        node.addEventListener(`mouseout`, handleMouseOut);

        return () => {
          node.removeEventListener(`mouseover`, handleMouseOver);
          node.removeEventListener(`mouseout`, handleMouseOut);
        };
      }
    },
    [] // Recall only if ref changes
  );

  return [ref, value] as [RefObject<T>, boolean];
}

export default useHover;
