import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography, TypographyProps } from '@material-ui/core';
import theme from 'src/shared/theme';

interface Props {
  className?: string;
}

const Title = styled(Typography)`
  margin-bottom: ${theme.spacing(3)};
  width: 100%;
`;

const SectionTitle: FC<Props & TypographyProps> = (props) => (
  <Title variant="h2" align="center" {...props}>
    {props.children}
  </Title>
);

export default SectionTitle;
