import React, { FC } from 'react';
import styled from 'styled-components';
import { TypographyProps, Theme } from '@material-ui/core';
import Text from 'components/shared/text';

interface Props {
  className?: string;
  bold?: boolean;
}

const Content = styled(Text)`
  font-weight: ${({ theme, bold }: {theme: Theme, bold?: boolean}) => (bold
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightRegular)
};
  font-size: 22px;
  line-height: 26px;
`;

const WelcomeSectionText: FC<Props & TypographyProps> = (props) => <Content {...props} />;

export default WelcomeSectionText;
