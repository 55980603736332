import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import Img, { FluidObjectWithPresentationWidth } from 'components/shared/img';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { SM, XS } from 'src/shared/media-query';
import swipeHandler from 'src/shared/swipe-handler';
import theme from 'src/shared/theme';
import styled, { css } from 'styled-components';

const Button = styled(IconButton)<{$imageLoaded}>`
  display: none;
  position: absolute;
  top: 50%;
  background: #fefefeee;
  z-index: 1;
  filter: drop-shadow(-10px 14px 30px rgba(0, 0, 0, 0.25));

  &:hover {
    background: white;
  }

  @media ${SM} {
    ${({ $imageLoaded }) => ($imageLoaded ? `display: block;` : `display: none`)}
  }
`;

const CarousalDot = styled.button<{ $active }>`
  border-radius: 1000px;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 ${theme.spacing(1)};
  padding: 0;
  opacity: 0.8;
  border: none;
  
  ${({ $active }) =>
    $active
      ? `background-color: ${theme.palette.common.white};`
      : `background-color: ${theme.palette.primary.main};`}

  @media ${XS} {
    width: 1rem;
    height: 1rem;
    margin: 0 ${theme.spacing(2)};
  }
`;

const DotsCarousal = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${theme.spacing(4)};

  @media ${XS} {
    margin-bottom: ${theme.spacing(8)};
  }

  @media ${SM} {
    display: none;
  }
`;

interface Props {
  className?: string;
  images: FluidObjectWithPresentationWidth[];
}

const INTERVAL = 2000;

const SlideShow: FC<Props> = ({ className, images }) => {
  const [image, setImage] = useState(images[0]);
  const [ref, isInView] = useInView();
  const [
    imageToPreload,
    setImageToPreload,
  ] = useState<FluidObjectWithPresentationWidth | null>();
  const [imageShown, setImageShown] = useState(false);

  const getNextImage = useCallback(
    ({
      currentImage,
      step,
    }: {
      currentImage: FluidObjectWithPresentationWidth;
      step: -1 | 1;
    }) => {
      const currentIndex = images.indexOf(currentImage);
      const nextIndex = currentIndex + step;

      if (nextIndex >= images.length) {
        return images[0];
      }
      if (nextIndex <= 0) {
        return images[images.length - 1];
      }
      return images[nextIndex];
    },
    [images]
  );

  const imageWasPreloaded = () => {
    setImage(imageToPreload as FluidObjectWithPresentationWidth);
    setImageToPreload(null);
  };

  useEffect(() => {
    if (isInView) {
      const timeout = setTimeout(() => {
        setImageToPreload(getNextImage({ currentImage: image, step: +1 }));
      }, INTERVAL);

      return () => clearTimeout(timeout);
    }
  }, [image, getNextImage, isInView]);

  return (
    <div
      className={className}
      css={css`
        position: relative;
        border-radius: 12px;
        display: flex;
      `}
      ref={ref}
    >
      <Button
        aria-label="Předchozí"
        color="primary"
        $imageLoaded={imageShown}
        css={css`
          left: 0;
          transform: translate(10%, -50%);
          @media ${SM} {
            transform: translate(-50%, -50%);
          }
        `}
        onClick={() =>
          setImage(image => getNextImage({ currentImage: image, step: -1 }))}
      >
        <ChevronLeft fontSize="large" />
      </Button>
      <Button
        aria-label="Další"
        color="primary"
        $imageLoaded={imageShown}
        css={css`
          right: 0;
          transform: translate(-10%, -50%);
          @media ${SM} {
            transform: translate(50%, -50%);
          }
        `}
        onClick={() =>
          setImage(image => getNextImage({ currentImage: image, step: +1 }))}
      >
        <ChevronRight fontSize="large" />
      </Button>
      <AnimatePresence>
        <motion.div
          key={image.src}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          css={css`
            width: 100%;
            height: 100%;
          `}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={swipeHandler(step =>
            setImage(image => getNextImage({ currentImage: image, step }))
          )}
        >
          <div
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              border-radius: 12px;
              overflow: hidden;
            `}
          >
            <Img
              src={image}
              style={{ height: `100%`, width: `100%` }}
              onLoad={() => setImageShown(true)}
            />
            {imageToPreload ? (
              <Img
                src={imageToPreload}
                onLoad={imageWasPreloaded}
                style={{
                  height: `100%`,
                  width: `100%`,
                  opacity: 0,
                }}
                loading="eager"
              />
            ) : null}
          </div>
        </motion.div>
      </AnimatePresence>
      <DotsCarousal>
        {images.map(img => (
          <CarousalDot
            $active={img === image}
            key={img.src}
            onClick={() => setImage(img)}
          />
        ))}
      </DotsCarousal>
    </div>
  );
};

export default SlideShow;
