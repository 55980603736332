import React from 'react';

import Layout from 'src/components/shared/layout';
import WelcomeSection from 'components/home-page/welcome-section';
import MainInfoSection from 'components/home-page/main-info-section';
import NewsSection from 'components/home-page/news-section';
import ServicesSection from 'components/home-page/services-section';
import OurTeamSection from 'components/home-page/our-team-section';
import Hat from 'components/shared/hat';

/*
  TODOs:
    test the redirections before deploying !! SEO
    paginator in news
    add validation on CMS fields - parts with links
*/

const IndexPage = () => (
  <Layout>
    <Hat />
    <WelcomeSection />
    <MainInfoSection />
    <NewsSection />
    <ServicesSection />
    <OurTeamSection />
  </Layout>
);

export default IndexPage;
