import React, { FC } from 'react';

interface Props {
  className?: string;
  maxLength: number;
}

const EllipsisText: FC<Props> = props => {
  const originalText =
    typeof props?.children === `string` ? props.children : ``;
  let textWithEllipsis = originalText;

  if (originalText.length > props.maxLength) {
    textWithEllipsis = textWithEllipsis.substring(0, props.maxLength);
    const lastSpace = textWithEllipsis.lastIndexOf(` `);
    const lastComma = textWithEllipsis.lastIndexOf(`,`);
    const lastDot = textWithEllipsis.lastIndexOf(`.`);
    const lastExclamation = textWithEllipsis.lastIndexOf(`!`);
    const lastQuestionMark = textWithEllipsis.lastIndexOf(`?`);
    const lastDelimiter = Math.max(
      lastSpace,
      lastComma,
      lastDot,
      lastExclamation,
      lastQuestionMark
    );

    textWithEllipsis = textWithEllipsis.substring(0, lastDelimiter + 1);
    textWithEllipsis += `...`;
  }

  return <span>{textWithEllipsis}</span>;
};

export default EllipsisText;
