import React, { FC } from 'react';
import styled from 'styled-components';
import { Icon as MaterialIcon } from '@material-ui/core';

import { SM, XS } from 'src/shared/media-query';
import EllipsisText from 'components/shared/ellipsis-text';
import theme from 'src/shared/theme';
import useHover from 'src/shared/hooks/useHover';
import Text from '../text';

interface Props {
  className?: string;
  Icon: typeof MaterialIcon;
  caption: string;
  description?: string;
  disabled?: boolean;
}

const OUTLINE_BORDER_WIDTH_IN_REM = 0.625;
const SMALL_OUTLINE_BORDER_WIDTH_IN_REM = 0.325;

const BorderMaker = styled.div`
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: visible;
`;

const MovingContainerPart = styled.div<{ $isHovered, $isDisabled }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.04);
  transition: 300ms ease;
  transition-property: background-color, transform, box-shadow;

  ${({ $isHovered }) =>
    $isHovered
      ? `
    background-color: ${theme.palette.primary.main};
    transform: translateY(-${theme.spacing(2)});
    box-shadow: 0px 10px 19px -8px rgba(0, 0, 0, 0.29);
  `
      : null}

  ${({ $isDisabled }) => $isDisabled ? `filter: grayscale(100%) invert(20%);` : ``}
`;

const Container = styled.div<{ $isHovered }>`
  border-radius: 12px;
  padding: ${theme.spacing(2)};
  position: absolute;
  height: calc(100% - 2 * ${SMALL_OUTLINE_BORDER_WIDTH_IN_REM}rem);
  width: calc(100% - 2 * ${SMALL_OUTLINE_BORDER_WIDTH_IN_REM}rem);
  top: ${SMALL_OUTLINE_BORDER_WIDTH_IN_REM}rem;
  left: ${SMALL_OUTLINE_BORDER_WIDTH_IN_REM}rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isHovered }) =>
    $isHovered ? theme.palette.primary.dark : theme.palette.common.white};
  transition: 300ms ease;
  transition-property: background-color;

  @media ${XS} {
    height: calc(100% - 2 * ${OUTLINE_BORDER_WIDTH_IN_REM}rem);
    width: calc(100% - 2 * ${OUTLINE_BORDER_WIDTH_IN_REM}rem);
    top: ${OUTLINE_BORDER_WIDTH_IN_REM}rem;
    left: ${OUTLINE_BORDER_WIDTH_IN_REM}rem;
  }

  @media ${SM} {
    padding: ${theme.spacing(8)};
    justify-content: space-evenly;
  }
`;

const IconWrapper = styled.div<{ $isHovered }>`
  font-size: 3rem;

  ${({ $isHovered }) =>
    $isHovered
      ? `filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(7%) hue-rotate(307deg) brightness(108%) contrast(100%);`
      : `filter: brightness(0) saturate(100%) invert(76%) sepia(66%) saturate(6444%) hue-rotate(160deg) brightness(93%) contrast(101%);`}

  @media ${XS}{
    font-size: 3rem;
  }
  @media ${SM}{
    font-size: 5rem;
  }
`;

const Title = styled(Text)<{ $isHovered }>`
  color: ${({ $isHovered }) =>
    $isHovered
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary};
  transition: 300ms ease;
  transition-property: color;
  font-size: 1.1rem;

  @media ${XS} {
    font-size: 1.25rem;
  }
`;

const Description = styled(Text)<{ $isHovered }>`
  color: ${({ $isHovered }) =>
    $isHovered
      ? theme.palette.text.darkContrast
      : theme.palette.text.lightContrast};
  transition: 300ms ease;
  transition-property: color;

  display: none;

  @media ${SM} {
    display: block;
  }
`;

const TileButton: FC<Props> = ({ className, Icon, caption, description, disabled }) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const hasHover = isHovered && !disabled;

  return (
    <BorderMaker className={className} ref={hoverRef}>
      <MovingContainerPart $isHovered={hasHover} $isDisabled={disabled}>
        <Container $isHovered={hasHover}>
          <IconWrapper $isHovered={hasHover}>
            <Icon style={{ fontSize: `inherit` }} color="secondary" />
          </IconWrapper>
          <Title variant="h4" $isHovered={hasHover}>
            {caption}
          </Title>
          {description ? (
            <Description variant="body1" $isHovered={hasHover}>
              <EllipsisText maxLength={95}>{description}</EllipsisText>
            </Description>
          ) : null}
        </Container>
      </MovingContainerPart>
    </BorderMaker>
  );
};

export default TileButton;
