import { DragHandler } from 'framer-motion/types/gestures/drag/types';

const SWIPE_CONFIDENCE_THRESHOLD = 1000;

type SwipeHandler = (onSwipe: (direction: -1 | 1) => void) => DragHandler;

const swipeHandler: SwipeHandler = onSwipe => {
  const dragHandler: DragHandler = (event, { offset, velocity }) => {
    const swipePower = Math.abs(offset.x) * velocity.x;

    if (swipePower < -SWIPE_CONFIDENCE_THRESHOLD) {
      onSwipe(1);
    } else if (swipePower > SWIPE_CONFIDENCE_THRESHOLD) {
      onSwipe(-1);
    }
  };

  return dragHandler;
};

export default swipeHandler;
