import { css } from "styled-components";
import theme from "../theme";

export default function getFlexGap(spacing = 4) {
  const gap = theme.spacing(spacing);

  return css`
    display: inline-flex;
    flex-wrap: wrap;
    margin: -${gap} 0 0 -${gap};
    width: calc(100% + ${gap});

    & > * {
      margin: ${gap} 0 0 ${gap};
    }
  `;
}
