import { useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useAnimationOnInView = ({ startVariant = `visible` }) => {
  const control = useAnimation();
  const [ref, isInView] = useInView();

  useEffect(() => {
    if (isInView) {
      control.start(startVariant);
    }
  }, [control, isInView, startVariant]);

  return { control, triggerElement: ref };
};

export default useAnimationOnInView;
