import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Button from 'components/shared/button';
import { useStaticQuery, graphql } from 'gatsby';
import SectionContainer from 'components/shared/section-container';
import SectionContent from 'components/shared/section-content';
import SectionTitle from 'components/shared/section-title';
import SectionSubtitle from 'components/shared/section-subtitle';
import theme from 'src/shared/theme';
import NewsCards from 'components/shared/news-cards';
import ShowUpMotion from 'components/shared/show-up-motion';

interface Props {
  className?: string;
}

const OtherNewsButton = styled(Button)`
  align-self: center;
  margin-top: ${theme.spacing(17)};
`;

const Container = styled(SectionContainer)`
  position: relative;

  &::after {
    content: '';
    background-image: url('assets/animal-silhoulettes.svg');
    position: absolute;
    top: -10%;
    height: 120%;
    width: 100%;
    z-index: -1;
  }
`;

const NewsSection: FC<Props> = props => {
  const queryResult = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "news-section" } }) {
          frontmatter {
            title
            subtitle
            otherNewsButtonText
          }
        }
        allMarkdownRemark(
          limit: 1000
          filter: { fileAbsolutePath: { regex: "/(/aktualne-u-nas/)/" } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date
                title
                description
              }
            }
          }
        }
      }
    `
  );

  const {
    title,
    subtitle,
    otherNewsButtonText,
  } = queryResult.markdownRemark.frontmatter;

  const newsPosts = queryResult.allMarkdownRemark.edges.map(edge => ({
    slug: edge.node.fields.slug,
    date: new Date(edge.node.frontmatter.date),
    title: edge.node.frontmatter.title,
    description: edge.node.frontmatter.description,
  })) as {
    slug: string;
    date: Date;
    title: string;
    description: string;
  }[];

  const sortedPosts = newsPosts.sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );
  const newestPosts = sortedPosts.filter((_, index) => index < 3);

  return (
    <Container className={props.className}>
      <SectionContent>
        <ShowUpMotion
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <SectionTitle>{title}</SectionTitle>
          <SectionSubtitle>{subtitle}</SectionSubtitle>
          <NewsCards newestPosts={newestPosts} />
        </ShowUpMotion>
        <OtherNewsButton
          to="/aktualne-u-nas"
          variant="contained"
          color="secondary"
          size="large"
        >
          {otherNewsButtonText}
        </OtherNewsButton>
      </SectionContent>
    </Container>
  );
};

export default NewsSection;
