import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import theme from 'src/shared/theme';
import { SM } from 'src/shared/media-query';

interface Props {
  className?: string;
  ref?: React.Ref<HTMLSpanElement>;
}

const Subtitle = styled(Typography)`
  text-align: center;
  font-size: 1.25rem;
  max-width: ${theme.breakpoints.values.md}px;
  margin-bottom: ${theme.spacing(10)};

  @media ${SM} {
    margin-bottom: ${theme.spacing(13)};
    padding-left: ${theme.spacing(28)};
    padding-right: ${theme.spacing(28)};
  }
`;

const SectionSubtitle: FC<Props> = React.forwardRef(
  (props, ref: React.Ref<HTMLSpanElement>) => (
    <Subtitle variant="body2" ref={ref}>
      {props.children}
    </Subtitle>
  )
);

export default SectionSubtitle;
